<script>
import omit from 'lodash.omit'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import { getAction, postAction } from '@/command/netTool'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'

export default {
  name: 'detailActivity',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        regionCode: [],
        address: '',
        imgUrl1: '',
        imgUrl2: ''
      },
      regionCode: []
    }
  },
  mounted() {
    const { id } = this.$route.query

    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'regionCode'
    })

    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/market/farmBanner/get?id=${id}`
        })
        .then(result => {
          this.detail['a1'] = this.detail.image2 || this.detail.image3
          this.detail.regionCode = [result.provinceCode, result.cityCode, result.areaCode]

          if (this.detail.imgUrl) {
            const imgUrlArr = this.detail.imgUrl.split(',')
            this.detail.imgUrl1 = imgUrlArr[0]
            this.detail.imgUrl2 = imgUrlArr[1]
          }

          this.$forceUpdate()
        })
    }
  },
  methods: {
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        onSuccess: data => {
          getAction(`/api/gao?location=${data.lng},${data.lat}`).then(e => {
            if (e.code == 200) {
              this.detail.regionCode = e.data.codes
              this.detail.address = e.data.address
              this.detail.lon = data.lng
              this.detail.lat = data.lat
            } else {
              this.$message.error(e.msg)
            }
          })
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            key: 'name',
            cols: 12,
            props: {
              maxLength: 76
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '活动地址',
            type: 'row',
            cols: 12,
            colsType: {
              lg: 24,
              md: 24,
              sm: 24,
              xs: 24,
              xxl: 12,
              xl: 18
            },
            gutter: 5,
            rules: [
              {
                required: true
              }
            ],
            children: [
              {
                type: 'button',
                label: '定位',
                cols: 3,
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap
                  }
                }
              },
              {
                type: 'cascader',
                cols: 10,
                key: 'regionCode',
                label: '地址',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址'
                  }
                ]
              },
              {
                type: 'input',
                cols: 11,
                key: 'address',
                label: '地址'
              }
            ]
          },
          {
            name: '排序序号（0-100）',
            type: 'inputNumber',
            key: 'sort',
            cols: 12,
            props: {
              min: 0,
              max: 100
            }
          },
          {
            name: '轮播停留时间',
            type: 'inputNumber',
            key: 'showSecond',
            cols: 12,
            props: {
              addonAfter: 's',
              min: 0,
              max: 100
            }
            // rules: [
            //   {
            //     required: true
            //   }
            // ]
          },
          {
            name: '活动开始时间',
            type: 'datePicker',
            key: 'beginTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm'
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '活动结束时间',
            type: 'datePicker',
            key: 'endTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm'
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '报名截止',
            type: 'datePicker',
            key: 'lastRegistTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm'
            },
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      let that = this
      return {
        type: 'cardForm',
        title: 'Banner',
        data: [
          {
            type: 'iconUpload',
            key: 'a1',
            typeData: [
              {
                maxLength: 1,
                key: 'imgUrl1',
                desc: '@2x   xhdpi <br/> 上传尺寸750*480px'
              },
              {
                maxLength: 1,
                key: 'imgUrl2',
                desc: '@3x   xhdpi <br/> 上传尺寸1125*720px'
              }
            ],
            rules: [
              {
                // eslint-disable-next-line max-params
                validator(rule, value, callback, source, options) {
                  if (!that.detail.imgUrl1 || !that.detail.imgUrl2) {
                    return false
                  } else {
                    return true
                  }
                },
                message: '请选择图片上传'
              }
            ]
          }
        ]
      }
    },
    getForm3() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            cols: 24
          }
        ]
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: () => {
            if (moment(this.detail.beginTime) > moment(this.detail.endTime)) {
              this.$message.warning('活动结束时间应在活动开始时间前！')
              return
            }
            if (moment(this.detail.endTime) < moment(this.detail.lastRegistTime)) {
              this.$message.warning('报名截止时间应在活动结束时间前！')
              return
            }
            let obj = {
              ...omit(this.detail, ['regionCode']),
              provinceCode: this.detail.regionCode[0],
              cityCode: this.detail.regionCode[1],
              areaCode: this.detail.regionCode[2],
              imgUrl: [this.detail.imgUrl1, this.detail.imgUrl2].join(',')
            }
            let lastRegistTime = this.detail.lastRegistTime
              ? moment(this.detail.lastRegistTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
              : null

            api.command[this.detail.id ? 'editPost' : 'create']
              .call(this, {
                url: `/market/farmBanner/${this.detail.id ? 'update' : 'add'}`,
                params: {
                  ...obj,
                  type: '2',
                  beginTime: moment(this.detail.beginTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                  endTime: moment(this.detail.endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                  lastRegistTime: lastRegistTime
                },
                isPost: false
              })
              .then(() => {
                this.$router.back()
              })
          }
        },
        {
          name: '取消',
          type: 'default',

          onClick: () => this.$router.push('/marketingCenter/bannerActivity')
        }
      ]
      const right = [
        {
          name: ` ${this.detail.status == '1' ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            postAction(`/market/farmBanner/${this.detail.status == '1' ? 'down' : 'up'}?id=${this.detail.id}`).then(
              e => {
                if (e.code == 200) {
                  this.$message.success(`${this.detail.status == '1' ? '下架成功' : '上架成功'}`)
                  setTimeout(() => {
                    this.$router.push('/marketingCenter/bannerActivity')
                  }, 500)
                } else {
                  this.$message.error(e.msg)
                }
              }
            )
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/market/farmBanner/delete?id=${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/bannerActivity')
            }, 500)
          }
        }
      ]
      return {
        left,
        right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        typeData={{
          regionCode: this.regionCode
        }}
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
